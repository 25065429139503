import React, { Component, useState } from 'react';
import Slider from 'react-slick';
import { MDBProgress } from 'mdbreact';
import { Header } from './layout/Header';
import { CarouselPage } from './banner/Home1_banner';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from 'html-react-parser';
import "./style.css";


const images = [
  'images/patleica.png',
  'images/pathexagon.png',
  'images/patesri.png',
  'images/patgissistem.png',
  'images/patoracle.png',
  'images/patdb-gaj.png'
];

const Home = ({}) => {
  // constructor(props) {
  //   super(props);
 
  //   this.state = {
  //     photoIndex: 0,
  //     isOpen: false,
  //   };
  // }
      // const { photoIndex, isOpen } = this.state;
      const [slick_slider, setSlick_slider] = useState({
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [{

          breakpoint: 1199,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 3
          }
      },
      {
    
          breakpoint: 1024,
          settings: {
          slidesToShow: 3,
          slidesToScroll: 3
          }
      },
      {
    
          breakpoint: 680,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      })

        
      const [dataClients, setDataClients] = useState([
        {
          name: "Sekolah AT-TAUFIQ Bogor",
          image: "images/swg-clients/AT-TAUFIQ.png",
        },
        {
          name: "BADAN INFORMASI GEOSPASIAL",
          image: "images/swg-clients/BADAN INFORMASI GEOSPASIAL.png",
        },
        {
          name: "BADAN NASIONAL PENGELOLA PERBATASAN",
          image: "images/swg-clients/BADAN NASIONAL PENGELOLA PERBATASAN.png",
        },
        {
          name: "BADAN PENGUSAHAAN BATAM",
          image: "images/swg-clients/BADAN PENGUSAHAAN BATAM.png",
        },
        {
          name: "BAKRIE GRUP",
          image: "images/swg-clients/BAKRIE GRUP.png",
        },
        {
          name: "COR GRUP",
          image: "images/swg-clients/COR GRUP.png",
        },
        {
          name: "DINKOMINFO KABUPATEN MUSI BANYUASIN",
          image: "images/swg-clients/DINKOMINFO KABUPATEN MUSI BANYUASIN.png",
        },
        {
          name: "KABUPATEN KEDIRI",
          image: "images/swg-clients/KABUPATEN KEDIRI.png",
        },
        {
          name: "KABUPATEN SIGI",
          image: "images/swg-clients/KABUPATEN SIGI.png",
        },
        {
          name: "KEMETERIAN INVESTASI BKPM",
          image: "images/swg-clients/KEMETERIAN INVESTASI BKPM.png",
        },
        {
          name: "LEMBAGA ILMU PENGETAHUAN INDONESIA",
          image: "images/swg-clients/LEMBAGA ILMU PENGETAHUAN INDONESIA.png",
        },
        {
          name: "PT. ALASKA DWIPA PERDANA",
          image: "images/swg-clients/PT. ALASKA DWIPA PERDANA.png",
        },
        {
          name: "PT. INDOTAN LOMBOK BARAT BANGKIT",
          image: "images/swg-clients/PT. INDOTAN LOMBOK BARAT BANGKIT.png",
        },
        {
          name: "PT. MEGA TRUSHLINK",
          image: "images/swg-clients/PT. MEGA TRUSHLINK.png",
        },
        {
          name: "UNITED TRACTORS",
          image: "images/swg-clients/UNITED TRACTORS.png",
        },
      ]);

        const [dataPartners, setDataPartners] = useState([
          {
            name: "GEOSERVER",
            image: "images/swg-partners/GEOSERVER.png",
          },
          {
            name: "SQL SERVER",
            image: "images/swg-partners/SQL SERVER.png",
          },
          {
            name: "AUTODESK",
            image: "images/swg-partners/AUTODESK.png",
          },
          {
            name: "CHCNAV",
            image: "images/swg-partners/CHCNAV.png",
          },
          {
            name: "DELL",
            image: "images/swg-partners/DELL.png",
          },
          {
            name: "ESRI",
            image: "images/swg-partners/ESRI.png",
          },
          {
            name: "IBM",
            image: "images/swg-partners/IBM.png",
          },
          {
            name: "LEICA",
            image: "images/swg-partners/LEICA.png",
          },
          {
            name: "LINUX",
            image: "images/swg-partners/LINUX.png",
          },
          {
            name: "ORACLE",
            image: "images/swg-partners/ORACLE.png",
          },
          {
            name: "POSTGRE SQL",
            image: "images/swg-partners/POSTGRE SQL.png",
          },
          {
            name: "TRIMBLE",
            image: "images/swg-partners/TRIMBLE.png",
          },
          {
            name: "WINDOWS",
            image: "images/swg-partners/WINDOWS.png",
          },
        ]);
        
        const [dataHome, setDataHome] = useState({
          sliders: [
            {
              image:"images/swg-slider/1.png",
            },
            {
              image:"images/swg-slider/2.png",
            },
            {
              image:"images/swg-slider/3.png",
            },
            {
              image:"images/swg-slider/4.png",
            },
            {
              image:"images/swg-slider/5.png",
            },
            {
              image:"images/swg-slider/6.png",
            },
            {
              image:"images/swg-slider/7.png",
            },
            {
              image:"images/swg-slider/8.png",
            }
          ],
          aboutus:{
            title:"PT. Sawerigading Multi Kreasi",
            image:"images/swg-tentang-kami/GAMBAR-2.png",
            description: `<p>PT. Sawerigading Multi Kreasi didirikan dengan keinginan yang kuat untuk memberikan 
            kontribusi signifikan terhadap masyarakat dalam upaya mewujudkan bangsa Indonesia 
            yang cerdas (smart nation).</p>

            <p>Memilih bentuk perusahaan jasa konsultansi, khususnya dibidang 
            industri Informasi Geospasial, Teknologi Informasi dan komunikasi (ICT), Survey dan Pemetaan, 
            SWG fokus pada optimalisasi pemanfaatan pengetahuan dan perkembangan teknologi geospasial, 
            ICT, Survey dan Pemetaan untuk mendukung proses pembangunan cerdas (smart development) sebagai 
            pra-syarat untuk mewujudkan bangsa yang cerdas. </p>

            <p>Dengan mengedepankan pengetahuan geospasial dan 
            ICT terkini (state of the art) yang didukung oleh jaringan tenaga ahli dan profesional yang memiliki pengetahuan, 
            keahlian, serta pengalaman terdepan, kami yakin dapat memberikan kualitas layanan/ produk terbaik dalam
            upaya mewujudkan smart development menuju smart nation.</p>`
          }
        })

        const [dataLayanans, setDataLayanans] = useState([
          {
            name: "DATA MANAGEMENT",
            description: `<p>Data adalah aset yang sangat bernilai, pe­nyediaannya mahal dan membutuhkan waktu. 
            Pengelolaannya harus profesioanal agar pemanfaatan serta perawatannya (main­tenance, 
            updating) maksimal dan efektif, serta mendukung pemanfaatan bersama melalui data sharing.
            <br><br>
            Jasa Inti :<br>
            Tata kelola dan masterplan data<br>
            Master data management<br>
            Data warehouse management<br>
            Business intelligence system
            </p>`,
            image: "images/swg-layanans/DATA MANAGEMNT.png",
          },
          {
            name: "E-GOVERMENT",
            description: `<p>Pelayanan berkualitas kepada masyarakat adalah tujuan pembangunan. 
            Pelayanan ini bisa berbentuk "front end" seperti kantor layanan masyarakat 
            maupun "back end" dalam bentuk pengambilan keputusan yang cepat dan tepat. 
            E-government adalah salah satu pilar smart development yang menunjang pelayanan 
            pemerintah berkualitas.<br><br>
              Jasa Inti :<br>
              Open Data - Satu Data<br>
              Executive dashboard<br>
              Aplikasi e - goverment
            </p>`,
            image: "images/swg-layanans/E-GOVERMENT.png",
          },
          {
            name: "GIS",
            description: `<p>Pembangunan berwawasan keruangan  (geo­spasial) adalah salah satu 
            pilar smart development karena akan mampu mengarahkan pengambilan 
            keputusan sesuai dengan kebu­tuhan dan kondisi wilayah dan sekitarnya. 
            <br><br>
            GIS merupakan sebuah sistem multi-disiplin yang mampu menjadi solusi 
            dengan mengin­tegrasikan data dan informasi geospasial dengan non-geospasial 
            untuk dianalisis sesuai dengan kebutuhan untuk kemudian menjadi acuan pengambilan 
            keputusan  pembangunan.<br><br>
              Jasa Inti :<br>
              Web GIS (Open source & ESRI)<br>
              Mobil GIS (android & IOS)<br>
              ESRI GIS Software<br>
            </p>`,
            image: "images/swg-layanans/GIS.png",
          },
          {
            name: "IT SOLUTION",
            description: `<p>IT Solution merupakan jasa dalam pengembangan teknologi IT untuk membantu 
            sebuah bisnis mencapai tujuannya dan membantu manusia dalam menyelesaikan 
            segala permasalahannya, baik dalam mengolah data, menyebarkan info, berkomunikasi, 
            dan masih banyak lagi yang lainnya dan tentunya dengan cara cepat dan akurat
            <br><br>
              Jasa Inti :<br>
              Payment switch<br>
              Data center (simpul jaringan dalam one map policy)<br>
              Command center
            </p>`,
            image: "images/swg-layanans/IT SOLUTION.png",
          },
          {
            name: "SURVEY MAPPING",
            description: `<p>Tersedianya data dan informasi mampu menjadi one stop service bagi keberhasilan 
            organisasi dalam menjalankan smart development. Solusi penyediaan data harus efektif 
            dan efisien dengan mempertimbangkan aspek strategis dan praktis. 
            <br><br>
              Jasa Inti :<br>
              Topografi survei<br>
              GNSS survei<br>
              Foto udara (Drone survei)
            </p>`,
            image: "images/swg-layanans/SURVEY MAPPING.png",
          },
        ]);
        return (       
          <div className="site-main">   
            <Header/>            
            {/* home banner */}
            <CarouselPage data={dataHome.sliders}/>{/* home banner end*/}            
            {/* service-section */}     
            <section className="ttm-row service-section position-relative z-1 clearfix">
              <div className="container-xl">
                <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey">
                  <div className="col-md-4">
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          <FontAwesomeIcon icon="map-marked-alt" /> {/*  ttm-icon */}  
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>SURVEY & MAPPING</h5>
                        </div>
                        <div className="featured-desc">
                          <p>Topografi survei, GNSS survei, Foto udara (Drone survei)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {/*  featured-icon-box */} 
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md">
                          <FontAwesomeIcon icon="clipboard-check" />{/*  ttm-icon */} 
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>IT SOLUTION</h5>
                        </div>
                        <div className="featured-desc">
                          <p>Payment switch, Data center, Command center</p>
                        </div>
                      </div>
                    </div>{/*  featured-icon-box END */}
                  </div>
                  <div className="col-md-4">
                    {/*  featured-icon-box */} 
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          <i className="flaticon flaticon-developer" />{/*  ttm-icon */} 
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>E-GOVERNMENT</h5>
                        </div>
                        <div className="featured-desc">
                          <p>Open Data - Satu Data, Executive dashboard, Aplikasi e - goverment</p>
                        </div>
                      </div>
                    </div>{/*  featured-icon-box END */}
                  </div>

                  <div className="col-md-6">
                    {/*  featured-icon-box */} 
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          <FontAwesomeIcon icon="clipboard-check" />{/*  ttm-icon */} 
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>GIS</h5>
                        </div>
                        <div className="featured-desc">
                          <p>Web GIS (Open source & ESRI), Mobil GIS (android & IOS), ESRI GIS Software</p>
                        </div>
                      </div>
                    </div>{/*  featured-icon-box END */}
                  </div>
                  <div className="col-md-6">
                    {/*  featured-icon-box */} 
                    <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white">
                      <div className="featured-icon">{/*  featured-icon */} 
                        <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                          <i className="flaticon flaticon-developer" />{/*  ttm-icon */} 
                        </div>
                      </div>
                      <div className="featured-content">{/*  featured-content */}
                        <div className="featured-title">{/*  featured-title */}
                          <h5>DATA MANAGEMENT</h5>
                        </div>
                        <div className="featured-desc">
                          <p>Tata kelola dan masterplan data, Master data management, Data warehouse management, Business intelligence system</p>
                        </div>
                      </div>
                    </div>{/*  featured-icon-box END */}
                  </div>
                </div>
              </div>
            </section>
            {/* service-section end */}
            {/* aboutus-section */}
            <section className="ttm-row aboutus-section clearfix" id="aboutus">
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-6">
                    {/* section title */}
                    <div className="section-title with-desc clearfix">
                      <div className="title-header">
                        <h5>TENTANG KAMI</h5>
                        <h2 className="title">{dataHome.aboutus.title}</h2>
                      </div>
                      <div className="title-desc">
                        {parse(dataHome.aboutus.description)}
                      </div>
                    </div>{/* section title end */}
                    {/* row */}
                    {/* <div className="row no-gutters mt-4">
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Web And Mobile Application </span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">New Domain Registration</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Data Text Synchronization</span></li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <ul className="ttm-list ttm-list-style-icon">
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Improving Our Website Design</span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Web &amp; Email Hosting Services </span></li>
                          <li><i className="far fa-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Artificial Intelligence Web App</span></li>
                        </ul>
                      </div>
                    </div>row END */}
                    {/* separator */}
                    <div className="separator">
                      <div className="sep-line mt-4 mb-4" />
                    </div>
                    {/* separator */}
                    <div className="fs-16 ttm-textcolor-darkgrey">
                      <span> CONTACT US </span>
                      <strong className="fs-18">: (0251) 8664554</strong>
                    </div>
                  </div>
                  <div className="col-lg-6 res-991-mt-40 res-991-text-center">
                    <div className="position-relative res-991-mt-30">
                      {/* ttm_single_image-wrapper */}
                      <div className="ttm_single_image-wrapper">
                        <img className="img-fluid" src={dataHome.aboutus.image} title="single-img-one" alt="single-img-one" />
                      </div>{/* ttm_single_image-wrapper end */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* aboutus-section end */}
            {/* history-section */}
            <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" id="jasa" />
              <div className="container-xl">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2">
                    {/* section title */}
                    <div className="section-title with-desc text-center clearfix">
                      <div className="title-header">
                        <h4 className="title">PT. Sawerigading Multi Kreasi</h4>
                        <h2 className="title"><span>Layanan Kami</span></h2>
                      </div>
                    </div>{/* section title end */}
                  </div>
                </div>
                <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider}>
                  {dataLayanans.map((item,index) => {
                    return (<div key={index} className="ttm-box-col-wrapper">
                        <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */} 
                          <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */} 
                            {/*  featured-icon-box */} 
                            <div className="featured-icon-box">
                              <div className="featured-icon">{/*  featured-icon */}
                                <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                                  <i><img src={item.image} alt="gis" /></i>{/*  ttm-icon */} 
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="ttm-history-box-border" />{/* ttm-history-box-border  */} 
                          <div className="ttm-history-box-details ttm-bgcolor-white">
                            <div className="ttm-historybox-title"><h5>{item.name}</h5></div>{/* historybox-title  */} 
                            <div className="ttm-historybox-description" style={{textAlign:"left"}}>{/* description  */} 
                              <p className='desc-layanan'>{parse(item.description)}</p>
                            </div>
                          </div>
                        </div>
                      </div>);
                  })}
                  
                </Slider>
              </div>
            </section>
           <section id="portofolio" className="ttm-row bottomzero-padding-section position-relative services-section ttm-bgcolor-darkgrey ttm-bg ttm-bgimage-yes bg-img7 clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-md-12">
                    {/* section title */}
                    <div className="section-title text-center with-desc clearfix">
                      <div className="title-header">
                        <h5>Look At Portfolio</h5>
                        <h2 className="title">Check out our <span>PORTOFOLIO</span></h2>
                      </div>
                    </div>{/* section title end */}
                  </div>
                </div>
                {/* row */}
                <div className="row multi-columns-row ttm-boxes-spacing-10px ">
                  <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" src="images/portoaplikasi.png" alt="image" />
                      </div>{/* featured-thumbnail end*/}
                      <div className="featured-content">
                        <div className="category">
                          <p>BADAN PENDAPATAN DAERAH KOTA PALU 2019</p>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + ''}>Aplikasi PBB P2 dan BPHT</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-imagebox */}
                  </div>
                  <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" src="images/portosistem.png" alt="image" />
                      </div>{/* featured-thumbnail end*/}
                      <div className="featured-content">
                        <div className="category">
                          <p>BADAN INFORMASI GEOSPASIAL 2019</p>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + ''}>Sistem Nama Rupa Bumi</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-imagebox */}
                  </div>
                  <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" src="images/portodashboard.png" alt="image" />
                      </div>{/* featured-thumbnail end*/}
                      <div className="featured-content">
                        <div className="category">
                          <p>BAPPEDA KAB. SIGI 2019</p>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + ''}>Dashboard Data Pemerintah</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-imagebox */}
                  </div>
                  <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" src="images/portoaplikasi2.png" alt="image" />
                      </div>{/* featured-thumbnail end*/}
                      <div className="featured-content">
                        <div className="category">
                          <p>DINKOMINFO KAB. MUBA 2020</p>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + ''}>Aplikasi Satu Data</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-imagebox */}
                  </div>
                  <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" src="images/portosurvey.png" alt="image" />
                      </div>{/* featured-thumbnail end*/}
                      <div className="featured-content">
                        <div className="category">
                          <p>PT GAYO MINERAL RESOURCES 2019,  PT LINGE MINERAL RESOURCES 2019, PT ALASKA DWIP PERDANA, PT BUKIT ASAM
                           </p>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + ''}>Survey Topologi</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-imagebox */}
                  </div>
                  <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" src="images/portosurvey2.png" alt="image" />
                      </div>{/* featured-thumbnail end*/}
                      <div className="featured-content">
                        <div className="category">
                          <p>PT ITAMATRA NUSANTARA, PT MULIA PASIFIC RESOURCES, PT ALASKA DWIPA PERDANA</p>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + ''}>Pengukuran dan Pemasangan Tanda Batas WIUP</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-imagebox */}
                  </div> 
                  <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                    {/* featured-imagebox */}
                    <div className="featured-imagebox featured-imagebox-portfolio style2">
                      {/* featured-thumbnail */}
                      <div className="featured-thumbnail">
                        <img className="img-fluid" src="images/portoaplikasi3.png" alt="image" />
                      </div>{/* featured-thumbnail end*/}
                      <div className="featured-content">
                        <div className="category">
                          <p>Produk Aplikasi</p>
                        </div>
                        <div className="featured-title">
                          <h5><a href={process.env.PUBLIC_URL + ''}> APLIKASI, EXECUTIVE DASHBOARD, MONITORING DASHBOARD, WEBSITE</a></h5>
                        </div>
                      </div>
                    </div>{/* featured-imagebox */}
                  </div> 
                </div>{/* row end */}
              </div>
            </section>
            {/* portfolio-section end */}
            {/* team-section */}
            <section className="ttm-row team-section res-991-pt-0 clearfix" id="clients">
              <div className="container-xl" >
                <div className="row align-items-center mb-5">
                  <div className="col-lg-12 res-991-text-center">
                    <div className="section-title style2 mb-0 clearfix">
                      <div className="title-header mb-0">
                        <h5>PT. Sawerigading Multi Kreasi</h5>
                        <h2 className="title">Meet our <span>CLIENTS</span></h2>
                      </div>
                    </div>
                  </div>
                </div>
                <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={3}>
                  {
                    dataClients.map((item,index) => {
                      return (<div key={index} className="ttm-box-col-wrapper">
                        <div className="featured-imagebox featured-imagebox-team style1">
                          <div className="featured-thumbnail">{/* featured-thumbnail */}
                            <center>
                              <img className="img-fluid" src={item.image} alt="" />
                            </center>
                          </div> 
                          <div className="featured-content box-shadow">
                            <div className="featured-title">{/* featured-title */}
                              <h5><a>{item.name}</a></h5>
                            </div>
                            
                          </div>
                        </div>
                      </div>);
                    })
                  }
                </Slider>
              </div>
            </section>
            {/* team-section end*/}
            {/* portfolio-section */}
            <section id="pat" className="ttm-row bottomzero-padding-section  ttm-bg ttm-bgimage-yes bg-img5 ttm-bgcolor-skincolor clearfix">
              <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
              <div className="container-xl pb-5 res-991-pb-0" >
                <div className="row align-items-center ttm-textcolor-white">
                  <div className="col-lg-12 res-991-text-center">
                    <div className="section-title style3 mb-0 clearfix">
                      <div className="title-header mb-0">
                        <h5>PT. SAWERIGADING MULTI KREASI</h5>
                        <h2 className="title"><span className="ttm-textcolor-white">PARTNER AND TECHNOLOGY</span></h2>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-5 res-991-text-center">
                    <div className="title-desc">A project is a temporary endeavor with a defined beginning and end, scope, and assigned resources.</div>
                  </div> */}
                </div>
              </div>
            </section>
            <section className="ttm-row mt-10 zero-padding-section clearfix">
              <div className="container-fluid p-0">
                {/* {isOpen && (
                  <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                      })
                    }
                  />
                )} */}
                <Slider className="row slick_slider ttm-boxes-spacing-10px" {...slick_slider} slidesToShow={5}>
                  {dataPartners.map((item,index) => {
                    return (<div key={index} className="ttm-box-col-wrapper">
                      <div className="featured-imagebox featured-imagebox-portfolio style1">
                          <div className="featured-thumbnail">
                            <center><img className="img-fluid" alt="" src={item.image} /></center>
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h5><a>{item.name}</a></h5>
                            </div>
                          </div>
                        </div>
                      </div>);
                  })}
                </Slider>
              </div>
            </section>
            {/* process-section end */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3963.8592620732634!2d106.815536!3d-6.539451!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x691ebd5eb55f9fb0!2sPT.%20Sawerigading%20Multi%20Kreasi!5e0!3m2!1sid!2sid!4v1659933085758!5m2!1sid!2sid" width="100%" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            
          </div>
        )
}


export default Home;

import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer, MDBRow } from
"mdbreact";

export const CarouselPage = ({data}) => {
    return (
        <MDBContainer fluid>
        <MDBRow>
        <MDBCarousel
        activeItem={1}
        length={data.length}
        showControls={true}
        showIndicators={false}
        id="Home_banner"
        >
        <MDBCarouselInner>
            {data.map((item,index) => {
                return (<MDBCarouselItem key={index} itemId={index+1}>
                <MDBView>
                    <img className="d-block w-100" src={item.image} alt="First slide" />
                </MDBView>
                {/* <MDBCarouselCaption>
                    <div className="container-xl">
                        <div className="carousel-caption-inner banner1">
                            <MDBAnimation className="slideInUp" count={1} delay="2s" >
                                <h2 className="ttm-textcolor-darkgrey flipInY">PT. <strong className="ttm-textcolor-skincolor">SAWERIGADING</strong> MULTI KREASI</h2>
                            </MDBAnimation>
                        </div>
                    </div>
                </MDBCarouselCaption> */}
            </MDBCarouselItem>);
            })}
            
        </MDBCarouselInner>
        </MDBCarousel>
        </MDBRow>
        </MDBContainer>
    );
}
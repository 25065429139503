import React, { Component } from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import Gap from './Gap';

export class Footer extends Component {
    render() {
        return (
            <footer className="footer widget-footer clearfix">
            {this.props.children}
                <div className="first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes bg-img1">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                <div className="container-xl">
                    <div className="row align-items-md-center">
                    <div className="col-lg-4 col-md-4 col-sm-12 order-md-2">
                        <div className="footer-logo text-sm-center">
                        <img data-src="images/LOGO1.png" className="img-fluid" alt="footer-logo" src="images/swg/LOGO SWG.png" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 order-md-1 res-767-mt-20">
                        <div className="text-left">
                            {/*  featured-icon-box */} 
                            <div className="featured-icon-box left-icon icon-align-top">
                                <div className="featured-icon">{/*  featured-icon */} 
                                <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                                    <i className="ti ti-location-pin" />{/*  ttm-icon */} 
                                </div>
                                </div>
                                <div className="featured-content">{/*  featured-content */} 
                                <div className="featured-desc">
                                    <p>Vila Bogor Indah Blok EE-2 No.9 Kota Bogor - Jawa Barat 16157</p>
                                </div>
                                </div>
                            </div>{/*  featured-icon-box END */}

                            {/*  featured-icon-box */} 
                            <div className="featured-icon-box left-icon icon-align-top mt-3">
                                <div className="featured-icon">{/*  featured-icon */} 
                                <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                                    
                                </div>
                                </div>
                                <div className="featured-content">{/*  featured-content */} 
                                    <div className="featured-desc">
                                        <h5>Total Kunjungan</h5>
                                        <div>
                                            <img src="https://counter9.stat.ovh/private/freecounterstat.php?c=pu7bhyfgufnwnfsp4zkj1d8m72nk17nf" border="0" title="free hit counter" alt="free hit counter"/>
                                        </div>
                                    </div>
                                </div>
                            </div>{/*  featured-icon-box END */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 order-md-3 res-767-mt-20">
                        <div className="text-sm-right">
                        <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"><i className="far fa-envelope" />marketing@swg.co.id</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
                <div className="container-xl">
                    <div className="row copyright align-items-center res-767-text-center">
                    <div className="col-md-6">
                        <div>
                        <span>Copyright © 2021&nbsp;<a href="/">PT.Sawerigading Multi Kreasi</a></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="text-md-right res-767-mt-10">
                        <div className="d-lg-inline-flex">
                            <ul id="menu-footer-menu" className="footer-nav-menu">
                            <li><Link smooth to="#aboutus">About Us</Link></li>
                            <li><Link smooth to="#jasa">Jasa</Link></li>
                            <li><Link smooth to="#portofolio">Portofolio</Link></li>
                            <li><Link smooth to="#clients">Client</Link></li>
                            <li><Link smooth to="#pat">Partner and Technology</Link></li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </footer>
        )
    }
}